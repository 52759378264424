import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import db from "../../../components/firebaseInit"
import {
    //BUTTONS
    primaryCTA, secondaryCTA, viewCategoriesBtn, viewProductsBtn,

    //DATA TABLE HEADERS
    productTableHeaders, categoriesTableHeaders,

    //FORM HEADERS
    newProductFormHeader, newCategoryFormHeader,
    editProductFormHeader, editCategoryFormHeader,
} from '../data/data'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        products: [],
        variantCategories: [],
        variantItems: [],
        variantSets: [],
        modifierRules: [],
        //BUTTONS
        primaryCTA, secondaryCTA, viewCategoriesBtn, viewProductsBtn,

        //DATA TABLE HEADERS
        productTableHeaders, categoriesTableHeaders,

        //FORM HEADERS
        newProductFormHeader, newCategoryFormHeader,
        editProductFormHeader, editCategoryFormHeader,
    },
    getters: {
        sortedvariantCategories: (state) => {
            return state.variantCategories
                .map((el) => ({
                    id: el.id,
                    name: el.name,
                }))
                .sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        variantCategoriesJustNames: (state) => {
            return state.variantCategories.map((el) => (el.name))
        },
        variantItemJustNames: (state) => {
            return state.variantItems.map((el) => (el.name))
        },
        variantSetsJustNames: state => {
            return state.variantSets.map((el) => el.name)
        },
        modifierRulesJustNames: (state) => {
            return state.modifierRules.map((el) => (el.name))
        },
    },
    mutations: {
        setProducts: (state, payload) => (state.products = payload),
        setVariantCategories: (state, payload) => (state.variantCategories = payload),
        addVariantCategories: (state, payload) => (state.variantCategories.push(payload)),
        updateVariantCategories: (state, payload) => {
            // Find index of doc in categories
            // Swap out with new object
            const index = state.variantCategories.findIndex(el => el.id === payload.id)
            state.variantCategories.splice(index, 1, payload)
        },
        deleteVariantCategories: (state, payload) => {
            const index = state.variantCategories.findIndex(el => el.id === payload)
            state.variantCategories.splice(index, 1)
        },
        setVariantItems: (state, payload) => (state.variantItems = payload),
        addVariantItems: (state, payload) => (state.variantItems.push(payload)),
        updateVariantItems: (state, payload) => {
            // Find index of doc in categories
            // Swap out with new object
            const index = state.variantItems.findIndex(el => el.id === payload.id)
            state.variantItems.splice(index, 1, payload)
        },
        deleteVariantItems: (state, payload) => {
            const index = state.variantItems.findIndex(el => el.id === payload)
            state.variantItems.splice(index, 1)
        },
        setVariantSets: (state, payload) => (state.variantSets = payload),
        setModifierRules: (state, payload) => (state.modifierRules = payload),
        addModifierRules: (state, payload) => (state.modifierRules.push(payload)),
        updateModifierRules: (state, payload) => {
            // Find index of doc in array
            // Swap out with new object
            const index = state.modifierRules.findIndex(el => el.id === payload.id)
            state.modifierRules.splice(index, 1, payload)
        },
        deleteModifierRules: (state, payload) => {
            const index = state.modifierRules.findIndex(el => el.id === payload)
            state.modifierRules.splice(index, 1)
        },
    },
    actions: {
        getProducts: async (context) => {
            try {
                const snapshot = await db.collection('products').where("product_status", "==", "Available").get()
                const products = snapshot.docs.map(doc => {
                    // destructure and reassign values to make them
                    // easier to work with
                    const { product_id: id,
                        product_name: name,
                        product_category: category,
                        product_price: price,
                        product_code: sku,
                        configured, variantSettings } = doc.data()
                    return { id, name, category, price, sku, configured, variantSettings }
                })
                context.commit("setProducts", products)
            } catch (error) {
                return new Error(error)
            }
        },
        getVariantCategories: async (context) => {
            try {
                const snapshot = await db.collection("variant_categories").get()
                const categories = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                }))
                context.commit("setVariantCategories", categories)
            } catch (error) { return new Error(error) }
        },
        addVariantCategories: async (context, id) => {
            // Get the new doc from the db
            try {
                const doc = await db.doc(`variant_categories/${id}`).get()
                context.commit("addVariantCategories", doc.data())
            } catch (error) {
                return new Error(error)
            }
        },
        updateVariantCategories: async (context, id) => {
            // Get the updated doc from the db
            try {
                const doc = await db.doc(`variant_categories/${id}`).get()
                context.commit("updateVariantCategories", doc.data())
            } catch (error) {
                return new Error(error)
            }
        },
        deleteVariantCategories: (context, id) => {
            context.commit("deleteVariantCategories", id)
        },
        getVariantItems: async (context) => {
            try {
                const snapshot = await db.collection("variant_items").get()
                const items = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                }))
                context.commit("setVariantItems", items)
            } catch (error) { return new Error(error) }
        },
        addVariantItems: async (context, id) => {
            // Get the new doc from the db
            try {
                const doc = await db.doc(`variant_items/${id}`).get()
                context.commit("addVariantItems", doc.data())
            } catch (error) {
                return new Error(error)
            }
        },
        updateVariantItems: async (context, id) => {
            // Get the updated doc from the db
            try {
                const doc = await db.doc(`variant_items/${id}`).get()
                context.commit("updateVariantItems", doc.data())
            } catch (error) {
                return new Error(error)
            }
        },
        deleteVariantItems: async (context, id) => {
            context.commit("deleteVariantItems", id)
        },
        getVariantSets: async (context) => {
            try {
                const snapshot = await db.collection("variant_sets").get()
                const sets = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                }))
                context.commit("setVariantSets", sets)
            } catch (error) { return new Error(error) }
        },
        getModifierRules: async (context) => {
            try {
                const snapshot = await db.collection("modifier_rules").get()
                const rules = snapshot.docs.map(el => el.data())
                context.commit("setModifierRules", rules)
            } catch (error) {
                return new Error(error)
            }
        },
        addModifierRules: async (context, id) => {
            // Get the new doc from the db
            try {
                const doc = await db.doc(`modifier_rules/${id}`).get()
                context.commit("addModifierRules", doc.data())
            } catch (error) {
                return new Error(error)
            }
        },
        updateModifierRules: async (context, id) => {
            // Get the updated doc from the db
            try {
                const doc = await db.doc(`modifier_rules/${id}`).get()
                context.commit("updateModifierRules", doc.data())
            } catch (error) {
                return new Error(error)
            }
        },
        deleteModifierRules: async (context, id) => {
            context.commit("deleteModifierRules", id)
        },
    },
    modules: {},
});