//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: '#e7e7f7',
    btn_ml_10: 'ml-10',
    btn_view_icon: 'mdi-eye',
}
//View catagories button data
const viewCategoriesBtn = {
    btn_categories_name: 'View Categories',
    btn_categories_to: '/categories',
}
//View products button data
const viewProductsBtn = {
    btn_products_name: 'View Products',
    btn_products_to: '/products',
}
//----------------------------------------------
//DATA TABLE HEADERS
//Products table
const productTableHeaders = [
    { text: 'Edit', value: 'product_id' },
    { text: 'Name', value: 'product_name' },
    { text: 'Category', value: 'product_category' },
    { text: 'Price', value: 'product_price' },
    { text: 'Product Code', value: 'product_code' },
    { text: 'Status', value: 'product_status' },
    { text: 'Date Added', value: 'product_date' },
]
//Categories table
const categoriesTableHeaders = [
    { text: 'Edit', value: 'category_id' },
    { text: 'Name', value: 'category_name' },
    { text: 'Category Status', value: 'category_status' },
    { text: 'Date Added', value: 'category_date' }
]

//----------------------------------------------
//FORM HEADERS
//Add Product Form Header
const newProductFormHeader = {
    form_header_title: 'Add New Product',
    form_header_to: '/products',
}
//Add Category Form Header
const newCategoryFormHeader = {
    form_header_title: 'Add New Category',
    form_header_to: '/categories',
}

//Edit Product Form Header
const editProductFormHeader = {
    form_header_title: 'Edit Product',
    form_header_to: '/products',
}
//Edit Category Form Header
const editCategoryFormHeader = {
    form_header_title: 'Edit Category',
    form_header_to: '/categories',
}

//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA, viewCategoriesBtn, viewProductsBtn,

    //DATA TABLE HEADERS
    productTableHeaders, categoriesTableHeaders,

    //FORM HEADERS
    newProductFormHeader, newCategoryFormHeader,
    editProductFormHeader, editCategoryFormHeader,
}