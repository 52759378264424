import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { showSnackbar } from '../../../globalActions/index'

export const captureUser = function (type) {
    //Captures user that created the category
    this[`${type}_created_by_id`] = firebase.auth().currentUser.uid;
    db.collection("users")
        .where("uid", "==", this[`${type}_created_by_id`])
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this[`${type}_created_by_name`] = doc.data().displayName;
            });
        }).catch((error) => this.error = error)
}
//
export const getCategories = function () {
    db.collection("categories")
        .orderBy("category_name")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data().category_name;
                //Pushes the customers name to the empty customers array in 'data' above
                this.categories.push(data);
            });
        });
}

export const addCategory = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return;
    this.loading = true;
    const newDocRef = db.collection("categories").doc();
    newDocRef
        .set({
            category_id: newDocRef.id,
            category_name: this.category_name,
            category_status: this.category_status,
            category_date: new Date().toISOString().slice(0, 10),
            category_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            category_created_by_id: this.category_created_by_id,
            category_created_by_name: this.category_created_by_name,
        })
        .then(() => {
            this.loading = false;
            showSnackbar("New category added successfully");
        }).catch((error) => this.error = error)
    this.$router.push("/categories");
}

//
export const updateCategory = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return
    this.loading_update = true;
    db.collection("categories")
        .where("category_id", "==", this.$route.params.category_id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref
                    .update({
                        category_id: this.category_id,
                        category_name: this.category_name,
                        category_status: this.category_status,
                    })
                    //Reroute to category table page after database has been updated
                    .then(() => {
                        this.loading_update = false;
                        showSnackbar("Category updated successfully");
                    }).catch((error) => (this.error = error))
                this.$router.push("/categories");
            });
        });
}

//
export const deleteCategory = function () {
    db.collection("categories")
        .where("category_id", "==", this.$route.params.category_id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.delete();
                //Redirects back to customer list page
                this.loading_delete = false;
                showSnackbar("Category deleted successfully");
            });
        }).catch((error) => this.error = error)
    this.$router.push("/categories");
}
//
export const addProduct = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return
    this.loading = true;
    var newDocRef = db.collection("products").doc();
    newDocRef
        .set({
            //Populated by the v-model values in the form
            //Sets the product_id == to the document id in database
            product_id: newDocRef.id,
            product_name: this.product_name,
            product_category: this.product_category,
            product_img: this.product_img,
            product_price: this.product_price,
            product_code: this.product_code,
            product_status: this.product_status,
            product_date: new Date().toISOString().slice(0, 10),
            product_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            product_created_by_id: this.product_created_by_id,
            product_created_by_name: this.product_created_by_name,
        })
        //Reroute to customers list after database is updated
        .then(() => {
            this.loading = false;
            showSnackbar("New product added successfully");
        }).catch((error) => error)
    this.$router.push("/products");
}

export const updateProduct = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return
    this.loading_update = true
    db.collection('products').where('product_id', '==', this.$route.params.product_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                doc.ref.update({
                    product_id: this.product_id,
                    product_name: this.product_name,
                    product_category: this.product_category,
                    product_img: this.product_img,
                    product_price: this.product_price,
                    product_code: this.product_code,
                    product_status: this.product_status,
                })
                    //Reroute to products table page after database has been updated
                    .then(() => {
                        this.loading_update = false
                        showSnackbar("Product updated successfully");
                    })
                this.$router.push('/products')
            })
        })
}

export const deleteProduct = function () {
    this.loading_delete = true
    db.collection('products').where('product_id', '==', this.$route.params.product_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                doc.ref.delete()
                this.loading_delete = false
                //Redirects back to previos route in history stack (which is 'products')
                showSnackbar("Product deleted successfully");
            })
            this.$router.push('/products')
        })
}