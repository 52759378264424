<template>
  <div>
    <Form
      ref="form"
      :form="form"
      :valid="valid"
      :loading="loading"
      :isNew="Object.keys($route.params).length === 0 ? true : false"
      @passChange="updateInput($event)"
      @passValid="updateValid($event)"
      @passRefresh="refreshInput($event)"
    />
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import {
  addCategory,
  updateCategory,
  deleteCategory,
  captureUser,
} from "../data/external_products";
import {
  initFormData,
  getFormData,
  assignFormData,
  updateInput,
  refreshInput,
  updateValid,
  fieldRules,
} from "../../../components/Forms/formInit";
//
const formDataArray = [
  "category_id",
  "category_name",
  "category_status",
  "category_date",
  "category_timestamp",
  "category_created_by_id",
  "category_created_by_name",
];
const formDataObj = initFormData(formDataArray);
export default {
  name: "CategoryForm",
  components: {
    Form: () => import("../../../components/Forms/Form.vue"),
  },
  data() {
    return {
      ...formDataObj,
      valid: false,
      loading: false,
      category_status: "Available",
      ...fieldRules,
    };
  },
  // "Form" must be declared as a computed function and not in the "data" return object
  // This is because we need access to the "this" context of the initial data properties
  computed: {
    form: function () {
      return {
        header: `${
          Object.keys(this.$route.params).length === 0 ? "Add New" : "Edit"
        } Category`,
        fields: [
          {
            id: 1,
            inputs: [
              {
                name: "Category Name",
                value: this.category_name,
                data: "category_name",
                type: "text",
                rules: {
                  required: this.fieldRules.required,
                  maxLength: this.fieldRules.maxLength,
                },
                required: true,
              },
              {
                name: "Category Status",
                value: this.category_status,
                data: "category_status",
                type: "select",
                items: ["Available", "Unavailable"],
              },
            ],
          },
        ],
        action: {
          add: () => {
            this.addCategory();
          },
          edit: () => this.updateCategory(),
          delete: () => this.deleteCategory(),
        },
      };
    },
  },
  //

  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.params).length === 0) next();
    const dbQuery = db
      .collection("categories")
      .where("category_id", "==", to.params.category_id);
    //
    getFormData(formDataArray, dbQuery, to, next);
  },
  created() {
    this.captureUser("category");
    const dbQuery = db
      .collection("categories")
      .where("category_id", "==", this.$route.params.category_id);
    if (Object.keys(this.$route.params).length !== 0)
      this.assignFormData(formDataArray, dbQuery);
  },
  methods: {
    updateInput,
    updateValid,
    refreshInput,
    addCategory,
    updateCategory,
    deleteCategory,
    captureUser,
    getFormData,
    assignFormData,
  },
};
</script>