<template>
    <v-container fluid>
        <v-row dense class="mt-5">
            <v-col cols="12" sm="12" md="12">
                <h4>Manufacturing Assemblies</h4>
            </v-col>
        </v-row>
        <!--Assemblies List-->
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <v-alert v-if="assigned_assemblies.length === 0" dense outlined color="grey" icon="mdi-alert-circle">No assemblies assigned to product</v-alert>
                <!-- List of assigned assemblies -->
                <v-list v-else dense>
                    <v-list-item-group>
                        <v-list-item v-for="(assembly, index) in assigned_assemblies" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>{{ assembly.assembly_name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ 'Qty: ' + assembly.assembly_quantity }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="removeAssembly(index)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <!--Add assembly-->
        <v-row dense>
            <v-col cols="12" sm="12" md="12">
                <v-btn @click="dialog = true" color="primary">Add assembly <v-icon right small>mdi-plus</v-icon></v-btn>
            </v-col>
        </v-row>

        <!-- Dialog for adding new assembly -->
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Add Assembly
                </v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="selectedAssembly"
                        :items="assemblySelectionList"
                        item-text="assembly_name"
                        item-value="assembly_id"
                        label="Select an Assembly"
                        return-object
                        clearable
                    ></v-autocomplete>
                    <v-text-field
                        v-model.number="assemblyQuantity"
                        label="Quantity"
                        type="number"
                        min="1"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="addAssembly">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
    import db from '../../../components/firebaseInit';

    export default {
        name: "ManufacturingAssemblies",
        props: {
            assigned_assemblies: Array,
        },
        data(){
            return {
                assemblies: [],
                dialog: false,
                selectedAssembly: null,
                assemblyQuantity: 1,
            }
        },
        computed: {
            assemblySelectionList() {
                const selectedAssemblyIds = new Set(this.assigned_assemblies.map(x => x.assembly_id));
                
                return this.assemblies.filter(x => !selectedAssemblyIds.has(x.assembly_id)).map(x => ({
                    ...x,
                    assembly_quantity: 0
                }));
            }
        },
        created() {
            this.getAssemblies();
        },
        methods: {
            async getAssemblies() {
                const docRef = db.collection("assemblies").orderBy("assembly_name");
                try {
                    const snapshot = await docRef.get();
                    this.assemblies = snapshot.docs.map(doc => {
                        const { assembly_name, assembly_id } = doc.data();
                        return { assembly_name, assembly_id };
                    });
                } catch (error) {
                    console.error;
                }
            },
            //
            addAssembly() {
                if (this.selectedAssembly && this.assemblyQuantity > 0) {

                    this.$emit("assembly-added", {
                        ...this.selectedAssembly,
                        assembly_quantity: this.assemblyQuantity
                    });
                    this.dialog = false;
                    this.selectedAssembly = null;
                    this.assemblyQuantity = 1;
                }
            },
            removeAssembly(index) {
                this.assigned_assemblies.splice(index, 1);
            }
        },
    }
</script>