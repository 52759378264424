<template>
    <v-container  fluid>
        <v-row dense>
            <v-col lg="8" offset-lg="2">
                <v-card-title>
                    <v-btn
                        to="/products"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>{{ $route.params.product_id ? 'Edit' : 'New' }} Product</h3>
                </v-card-title>
                <v-card>
                    <v-form @submit.prevent="submitProduct" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="product_name"
                                        :rules="[v => !!v || 'A name name is required']"
                                        label="Product Name"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    />
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete
                                        v-model="product_category"
                                        :items="categories"
                                        item-text="category_name"
                                        item-value="category_name"
                                        cache-items
                                        label="Category"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                        class="font-weight-bold"
                                        :menu-props="{ closeOnClick: true }"
                                    />
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="product_code"
                                        label="Code"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    />
                                </v-col>
                                <!-- Satus -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete
                                        v-model="product_status"
                                        :items="statusList"
                                        cache-items
                                        label="Status"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                        :menu-props="{ closeOnClick: true }"
                                    />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model.number="product_price"
                                        label="Price"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    />
                                </v-col>
                            </v-row>
                            <!-- Variants -->
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <v-data-table
                                        v-model="selected_variants"
                                        :headers="variantHeaders"
                                        :items="variant_sets"
                                        :items-per-page="500"
                                        item-key="variant_set_id"
                                        show-select
                                        @item-selected="onVariantSelected"
                                        hide-default-header
                                        hide-default-footer
                                    >
                                        <!-- Table headers -->
                                        <template v-slot:header>
                                            <thead>
                                                <tr>
                                                    <h4 class="pb-3">Variants</h4>
                                                </tr>
                                            </thead>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <!--Manufacturing Assemblies-->
                            <ManufacturingAssemblies :assigned_assemblies="product_assigned_assemblies" @assembly-added="handleAssemblyAdded" />
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            to='/products'
                            color="#ce2458"
                            text
                            x-large
                        >
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="$route.params.product_id"
                            @click="deleteProduct"
                            color="red"
                            text
                            x-large
                            :loading="loading"
                        >
                            Delete
                            <v-icon right>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                            @click="submitProduct"
                            color="#33cc99"
                            text
                            x-large
                            :loading="loading"
                        >
                            {{ $route.params.product_id ? 'Update' : 'Submit' }}
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit';
import firebase from "firebase";
import { showSnackbar } from '../../../globalActions/index';
import ManufacturingAssemblies from '../components/ManufacturingAssemblies.vue';

export default {
    name: "ProductForm",
    components: {
        ManufacturingAssemblies,
    },
    data(){
        return {
            valid: false,
            loading: false,
            categories: [],
            statusList: ["Available", "Unavailable"],
            variantHeaders: [
                { text: 'Variant Set', value: 'variant_set_name' },
            ],
            variant_sets: [],
            selected_variants: [],
            product_id: null,
            product_name: null,
            product_category: null,
            product_code: null,
            product_price: null,
            product_status: "Available",
            product_variants: [],
            product_assigned_assemblies: [],
            product_date: null,
            product_timestamp: null,
            product_created_by_id: null,
            product_created_by_name: null,
        }
    },
    methods: {
        //
        async submitProduct() {
            // Form validation
            if(!this.$refs.form.validate()) return;

            this.loading = true;

            try {
                const productData = {
                    product_name: this.product_name,
                    product_category: this.product_category,
                    product_code: this.product_code,
                    product_price: this.product_price,
                    product_status: this.product_status,
                    product_variants: this.product_variants,
                    product_assigned_assemblies: this.product_assigned_assemblies,
                    product_date: new Date().toISOString().slice(0, 10),
                    product_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    product_created_by_id: this.product_created_by_id,
                    product_created_by_name: this.product_created_by_name,
                };

                // update existing product

                if (this.$route.params.product_id) {
                    // Update existing set
                    await db.collection('products').doc(this.$route.params.product_id).update(productData);
                    showSnackbar("Product updated successfully!");
                } else {
                     // Create new variant set
                    const newDocRef = db.collection('products').doc();
                    await newDocRef.set({ product_id: newDocRef.id, ...productData });
                    showSnackbar("New product created successfully!");
                }

                this.$router.push('/products');

            } catch(error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        // Delete product
        async deleteProduct() {
            if (!this.$route.params.product_id) return;

            if (!confirm("Are you sure you want to delete this product?")) return;

            this.loading = true;

            try {
                await db.collection('products').doc(this.$route.params.product_id).delete();
                showSnackbar("Product deleted successfully!");
                this.$router.push('/products');
            } catch (error) {
                console.error("Error deleting product: ", error);
                showSnackbar("Error deleting product.");
            } finally {
                this.loading = false;
            }
        },
        //
        onVariantSelected({ item, value }) {
            if (value) {
                console.log("Working", item);
                // Item selected, add variant_set_id and variant_set_name to product_variants
                this.product_variants.push({
                    variant_set_id: item.variant_set_id,
                    variant_set_name: item.variant_set_name,
                });
                console.log("Inside")
            } else {
                // Item deselected, remove it from product_variants
                const index = this.product_variants.findIndex(v => v.variant_set_id === item.variant_set_id);
                if (index > -1) {
                    this.product_variants.splice(index, 1);
                }
            }
        },
        // Load data for edit mode
        async loadProductData(productId) {
            this.loading = true;
            const docRef = db.collection('products').doc(productId);
            const doc = await docRef.get();
            if (doc.exists) {
                const data = doc.data();
                this.product_name = data.product_name;
                this.product_category = data.product_category;
                this.product_code = data.product_code;   
                this.product_price = data.product_price;
                this.product_status = data.product_status;
                this.product_variants = data.product_variants || [];
                this.selected_variants = data.product_variants || [];
                this.product_assigned_assemblies = data.product_assigned_assemblies || [];
            } else {
                console.log("No such document!");
            }
            this.loading = false;
        },
        // Get category list from database
        getCategories() {
            const docRef = db.collection("categories").orderBy("category_name");

            docRef.onSnapshot((snapshot) => {
                this.categories = [];
                this.categories = snapshot.docs.map(doc => doc.data());
            });
        },
        // get variant sets from database
        getVariantSets() {
            const docRef = db.collection("variant_sets").orderBy("variant_set_name");

            docRef.onSnapshot((snapshot) => {
                this.variant_sets = [];
                this.variant_sets = snapshot.docs.map(doc => {
                    const { variant_set_id, variant_set_name } = doc.data();
                    return { variant_set_id, variant_set_name };
                });
            });
        },
        // Get user who created the product
        async getUser() {
            this.product_created_by_id = firebase.auth().currentUser.uid;

            try {
                const querySnapshot = await db.collection("users")
                    .where("uid", "==", this.product_created_by_id)
                    .get();

                this.product_created_by_name = querySnapshot.docs[0].data().displayName;

            } catch (error) {
                console.error("Error fetching user: ", error);
            }
        }, 
        //
        handleAssemblyAdded(assembly) {
            this.product_assigned_assemblies.push(assembly);
        },
    },
    created() {
        this.getUser();
        this.getCategories();
        this.getVariantSets();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // `vm` is the instance of the incoming component
            if (to.params.product_id) {
                vm.loadProductData(to.params.product_id);
            }
        });
    },
}
</script>