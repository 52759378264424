<template>
    <div>
         <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Categories</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <!--Add category button-->
                        <NewProductButton
                          v-if="!firstLoad"
                          v-show="this.user_roles_list.flat().includes('products_write')"
                        />
                        <ButtonCTA
                            :button_name="viewProductsBtn.btn_products_name"
                            :button_to="viewProductsBtn.btn_products_to"
                            :button_color="secondaryCTA.btn_secondary_color"
                            :button_icon="secondaryCTA.btn_view_icon"
                            :button_margin_left="secondaryCTA.btn_ml_10"
                            v-show="this.user_roles_list.flat().includes('products_read')"
                            v-if="!firstLoad"
                        >
                        </ButtonCTA>
                    </v-card-title>
                </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="500">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('products_read')">
              <v-col>
                <v-sheet elevation="4">
                    <!--Customer data table-->    
                    <template>
                      <div>
                        <v-data-table
                          :headers="categoriesTableHeaders"
                          :items="filteredItems"
                          :search="search"
                          sort-by="category_name"
                        >
                        <!--Filter categories by availability status--> 
                        <template v-slot:top>
                          <v-container fluid>
                            <v-row> 
                              <v-col lg="3" md="3">
                                <v-select
                                  :items="['Available','Unavailable']"
                                  v-model='availableStatus'
                                  label="Availability Status"
                                  outlined
                                  flat
                                  solo
                                  dense
                                  append-icon="mdi-filter-variant"
                                >
                                  <template>
                                    <v-list-item slot="prepend-item">
                                        <h5>Filter by Availability</h5>
                                    </v-list-item>
                                </template>
                              </v-select> 
                            </v-col> 
                            <v-spacer />
                              <!--Search bar filter -->
                              <v-col lg="4" md="4">
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                rounded
                                dense
                                filled
                                clearable
                                ></v-text-field>
                              </v-col> 
                            </v-row>
                          </v-container>
                      </template>
                        <template
                          v-slot:item.category_id={item}
                          v-if="this.user_roles_list.flat().includes('products_write')"
                        >
                            <v-btn
                                :to="{ name: 'edit-category', params: { category_id: item.category_id }}"
                                color="#3d2cdd"
                                icon
                                small
                            ><v-icon small>mdi-pencil</v-icon></v-btn>
                        </template>
                        <!--Disable edit button based on user roles-->
                        <template
                          v-slot:item.category_id
                          v-else
                        >
                          <v-btn
                              color="#3d2cdd"
                              icon
                              small
                              disabled
                          ><v-icon small>mdi-pencil-off</v-icon></v-btn>
                        </template>
                        <template v-slot:item.category_status="props">
                            <v-icon 
                            left
                            x-small
                            :color="formatAvailability(props.item.category_status)">mdi-circle</v-icon>{{props.item.category_status}}
                        </template>
                        <template v-slot:no-data>
                          <v-container>
                            <v-row>
                              <v-col>
                                <div class="my-3">
                                  <h3>Welcome To Your Category List</h3>
                                  <p>
                                      Here you can add or edit categories that help<br>
                                      organise your product list
                                  </p>
                                  <v-btn
                                    color="#33cc99"
                                    dark
                                    to='/new-category'
                                    ><v-icon left>mdi-plus</v-icon>Add Category</v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                      </template>
                        </v-data-table>
                      </div>
                    </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewProductButton from './NewProductButton'
import ButtonCTA from '../../../components/Buttons/ButtonCTA'
import store from '../store/store'
export default {
    name: 'CategoriesTable',
    mixins: [mixin_UserRoles],
    components: {NewProductButton, ButtonCTA},
    data () {
      return {
        pagination: {},
        search: '',
        categories: [],
        //Used with filtering table by availability
        availableStatus: 'Available',
      }
    },
    // Gets categorie from firebase datatbase
    created(){
      db.collection('categories').get().then(querySnapshot => {
        this.categories = []
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'category_id': doc.data().category_id,
            'category_name': doc.data().category_name,
            'category_status': doc.data().category_status,
            'category_date': doc.data().category_date,
            
          }
          //Pushes the categories objects to the empty categories array above
          this.categories.push(data)
        })
      })
    },
    computed: {
      filteredItems() {
        return this.categories.filter((i) => 
          {
            return this.availableStatus === '' || (i.category_status === this.availableStatus)
          })
        },
        //This is where data is retrieved from the Vuex state
        viewProductsBtn(){return store.state.viewProductsBtn},
        secondaryCTA(){return store.state.secondaryCTA},
        categoriesTableHeaders(){return store.state.categoriesTableHeaders},
    },
    methods: {
      formatAvailability (category_status) {
        if (category_status == 'Available') return '#33cc99'
        else return '#f77f9c'
      },
    }
}
</script>