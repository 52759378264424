<template>
    <div>
        <ProductsTable />
    </div>
</template>

<script>
import ProductsTable from '../components/ProductsTable'
export default {
    name: 'Products',
    components: {ProductsTable}
}
</script>