<template>
    <div>
        <CategoriesTable />
    </div>
</template>

<script>
import CategoriesTable from '../components/CategoriesTable'
export default {
    name: 'Categories',
    components: {CategoriesTable}
}
</script>