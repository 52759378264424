<template>
    <div>
         <v-container fluid>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Products</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <!--Add new product button-->
                        <NewProductButton
                          v-if="!firstLoad"
                          
                        />
                        <!--Categories button-->
                        <ButtonCTA
                            :button_name="viewCategoriesBtn.btn_categories_name"
                            :button_to="viewCategoriesBtn.btn_categories_to"
                            :button_color="secondaryCTA.btn_secondary_color"
                            :button_icon="secondaryCTA.btn_view_icon"
                            :button_margin_left="secondaryCTA.btn_ml_10"
                            v-show="this.user_roles_list.flat().includes('products_read')"
                            v-if="!firstLoad"
                        />
                        
                    </v-card-title>
                </v-col>
              </v-row>
              <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="500">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('products_read')">
            <v-col>
              <v-sheet elevation="4">
                    <!--Customer data table-->    
                    <template>
                        <v-data-table
                          :headers="productTableHeaders"
                          :items="filteredItems"
                          :search="search"
                          sort-by="product_name"
                          :items-per-page="250"
                        >
                        <template v-slot:top>
                          <v-container fluid>
                            <v-row>
                        <!--Filter products by availability status-->  
                          <v-col lg="3" md="3">
                            <v-select
                              :items="['Available','Unavailable']"
                              v-model='availableStatus'
                              label="Availability Status"
                              outlined
                              flat
                              solo
                              dense
                              append-icon="mdi-filter-variant"
                            >
                              <template>
                                <v-list-item slot="prepend-item">
                                    <h5>Filter by Availability</h5>
                                </v-list-item>
                            </template>
                          </v-select> 
                        </v-col> 
                        <v-spacer />
                              <!--Search bar filter -->
                              <v-col lg="4" md="4">
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                rounded
                                dense
                                filled
                                clearable
                                ></v-text-field>
                              </v-col> 
                            </v-row>
                          </v-container>
                      </template>
                        <!--Edit icon button-->
                        <template
                          v-slot:item.product_id={item}
                          v-if="this.user_roles_list.flat().includes('products_write')"
                        >
                            <v-btn
                                :to="{ name: 'edit-product', params: { product_id: item.product_id }}"
                                color="#3d2cdd"
                                icon
                                small
                            ><v-icon small>mdi-pencil</v-icon></v-btn>
                        </template>
                        <!--Disable edit button based on user roles-->
                        <template
                          v-slot:item.product_id
                          v-else
                        >
                          <v-btn
                              color="#3d2cdd"
                              icon
                              small
                              disabled
                          ><v-icon small>mdi-pencil-off</v-icon></v-btn>
                        </template>
                        <!--Availability colour formatting-->
                        <template v-slot:item.product_status="props">
                            <v-icon 
                            left
                            x-small
                            :color="formatAvailability(props.item.product_status)">mdi-circle</v-icon>{{props.item.product_status}}
                        </template>
                        <!--Empty datatable slot-->
                        <template v-slot:no-data>
                          <v-container>
                            <v-row>
                              <v-col>
                                <div class="my-3">
                                  <h3>Welcome To Your Products List</h3>
                                  <p>
                                      Here you can add new products to your system,<br>
                                      or edit and view the details of existing ones
                                  </p>
                                  <v-btn
                                    color="#33cc99"
                                    dark
                                    to='/new-product'
                                    ><v-icon left>mdi-plus</v-icon>Add Product</v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        </v-data-table>
                    </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewProductButton from './NewProductButton'
import ButtonCTA from '../../../components/Buttons/ButtonCTA'
import store from '../store/store'
export default {
    name: 'ProductsTable',
    mixins: [mixin_UserRoles],
    components: {NewProductButton, ButtonCTA},
    data () {
      return {
        pagination: {},
        search: '',
        products: [],
        //Used with filtering table by availability
        availableStatus: 'Available',
        
      }
    },
    // Gets product values from firebase datatbase
    created(){
      db.collection('products').get().then(querySnapshot => {
        this.products = []
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'product_id': doc.data().product_id,
            'product_name': doc.data().product_name,
            'product_img': doc.data().product_img,
            'product_category': doc.data().product_category,
            'product_price': doc.data().product_price,
            'product_code': doc.data().product_code,
            'product_status': doc.data().product_status,
            'product_date': doc.data().product_date,
          }
          //Pushes the products objects to the empty customers array above
          this.products.push(data)
        })
      })
    },
     computed: {
      filteredItems() {
        return this.products.filter((i) => 
          {
            return this.availableStatus === '' || (i.product_status === this.availableStatus)
          })
        },
        //This is where data is retrieved from the Vuex state
        viewCategoriesBtn(){return store.state.viewCategoriesBtn},
        secondaryCTA(){return store.state.secondaryCTA},
        productTableHeaders(){return store.state.productTableHeaders},
        
    },
    methods: {
      formatAvailability (product_status) {
        if (product_status == 'Available') return '#33cc99'
        else return '#f77f9c'
      }
    }
}
</script>